<router-outlet></router-outlet>
<ng-container *ngIf="route.children.length === 0">
  <div class="container-one-to-one-schedule-view">
    <app-state [state]="state">
      <ng-container *ngIf="!state.loading && !state.error">
        <div class="schedule-details">
          <div class="schedule-info">
            <!-- Purpose and status -->
            <h4 class="standard-heading">
              <span>{{schedule.purposeTitle}}</span>
              <span class="m-right-10"></span>
              <ng-container [ngSwitch]="schedule.status">
                <ng-container *ngSwitchCase="eOneToOneStatus.ARCHIVED">
                  <app-pill [type]="ePillType.NAVY">
                    <span class="fal fa-fw fa-inbox"></span>
                    <span class="m-right-5"></span>
                    <span translate>{{eCommonMessages.ARCHIVED}}</span>
                  </app-pill>
                </ng-container>
                <ng-container *ngSwitchCase="eOneToOneStatus.PAUSED">
                  <app-pill [type]="ePillType.GRAY">
                    <span class="fal fa-fw fa-pause"></span>
                    <span class="m-right-5"></span>
                    <span translate>{{eOneToOneMessages.PAUSED}}</span>
                  </app-pill>
                </ng-container>
                <ng-container *ngSwitchCase="eOneToOneStatus.COMPLETED">
                  <app-pill [type]="ePillType.GREEN">
                    <span class="fal fa-fw fa-check"></span>
                    <span class="m-right-5"></span>
                    <span translate>{{eOneToOneMessages.COMPLETED}}</span>
                  </app-pill>
                </ng-container>
                <ng-container *ngSwitchCase="eOneToOneStatus.ACTIVE"></ng-container>
              </ng-container>
            </h4>

            <!-- Location -->
            <div class="schedule-location">
              <span class="fal fa-map-marker-alt"></span>
              <span class="m-right-5"></span>

              <!-- Location Type -->
              <ng-container *ngIf="schedule.locationType && locationTypeFormatted">
                <span class="location-type" translate>{{locationTypeFormatted}}</span>
                <span class="m-right-5"></span>
              </ng-container>

              <!-- Location Details -->
              <ng-container *ngIf="schedule.location.length > 0 && !locationIsMicrosoftTeamsUrl">
                <ng-container *ngIf="locationDetailsIsUrl; else notUrl">
                  <span> - </span>
                  <a class="location-details location-url" (click)="navigateToLocationDetailsUrl(schedule.location)">{{schedule.location}}</a>
                </ng-container>
                <ng-template #notUrl>
                  <span> - </span>
                  <span class="location-details">{{schedule.location}}</span>
                </ng-template>
              </ng-container>

              <!-- Call on teams button -->
              <ng-container *ngIf="shouldShowCallOnTeamsButton">
                <span class="m-right-5"></span>
                <app-button [type]="eButtonType.MICROSOFT_TEAMS" [small]="true" (onClick)="tryCallOnTeams()" [tooltipText]="eOneToOneMessages.START_TEAMS_CALL_TOOLTIP | translate" [tooltipPlacement]="'right'">
                  <span class="fab fa-fw fa-microsoft"></span>
                  <span class="m-right-5"></span>
                  <span translate>{{eOneToOneMessages.JOIN_CALL}}</span>
                </app-button>
              </ng-container>
            </div>

            <!-- Linked Cycle -->
            <ng-container *ngIf="linkedEvaluationCycle">
              <div class="text-info">
                <span class="clickable" (click)="setSide(eSideViewed.REVIEW_CYCLE_DETAILS)" [tooltip]="userIsManager ? (eOneToOneMessages.LINKED_TO_REVIEW_CYCLE_NAME_CREATOR_TOOLTIP | translate:{ participantFirstName: userOther.firstName }) : (eOneToOneMessages.LINKED_TO_REVIEW_CYCLE_NAME_PARTICIPANT_TOOLTIP | translate)" [triggers]="globals.tooltipTriggers">
                  <span class="fal fa-fw fa-clipboard-user"></span>
                  <span class="m-right-5"></span>
                  <span translate [translateParams]="{ reviewCycleName: linkedEvaluationCycle.name }">{{eOneToOneMessages.LINKED_TO_REVIEW_CYCLE_NAME}}</span>
                  <span class="m-right-5"></span>
                  <span>({{linkedEvaluationCycle.periodStart | momentPipe: 'MMM YY'}} - {{linkedEvaluationCycle.periodEnd | momentPipe: 'MMM YY'}})</span>
                </span>
              </div>
            </ng-container>
          </div>

          <!-- Participant profile pics -->
          <div class="display-schedule-participants">
            <div class="wrapper-schedule-manager">
              <div class="owner-img" *ngIf="schedule.manager" [title]="schedule.manager.firstName + ' ' + schedule.manager.lastName">
                <app-profile-photo [src]="schedule.manager.imageUrl" [firstName]="schedule.manager.firstName" [lastName]="schedule.manager.lastName"></app-profile-photo>
              </div>
              <span class="fas fa-fw fa-crown text-warning"></span>
            </div>
            <app-person-picture-list [users]="schedule.participants"></app-person-picture-list>
          </div>

          <div class="control-advanced-search" [class.search-no-results]="controlAdvancedSearch.value && !advancedSearchRunning && meetingIdsHighlighted.length === 0">
            <input class="form-control" [formControl]="controlAdvancedSearch"/>
            <span class="fal fa-fw fa-search"></span>
          </div>
        </div>

        <div class="main-cards">
          <div class="meeting-panel">
            <div class="meeting-list">
              <app-meeting-navigation-carousel [disabled]="state.changingMeeting" [schedule]="schedule" [meetings]="schedule.meetingList" [formControl]="meetingNavigationControl" [highlightedIds]="meetingIdsHighlighted"></app-meeting-navigation-carousel>
            </div>

            <div class="card">
              <div class="meeting-info">
                <app-one-to-one-meeting-view [schedule]="schedule" [meeting]="meeting" [meetingPrevious]="meetingPrevious" [calendarConnectionStatus]="calendarConnectionStatus" (onMeetingUpdated)="onMeetingUpdatedReceived($event)" (onSharedNotesUpdated)="onSharedNotesUpdatedReceived($event)" (onTalkingPointUpdated)="onTalkingPointUpdated($event)" #meetingView></app-one-to-one-meeting-view>
              </div>
            </div>
          </div>

          <div class="sidebar-panel">
            <div *ngIf="schedule.evaluationCycleId;else noCycle">
              <app-information-sidebar [tabsShown]="sidebarTabs" [userIds]="sidebarUserIds" [schedule]="schedule" [cycleId]="schedule.evaluationCycleId" [meetingNavigationControl]="meetingNavigationControl" [publicGoalsOnly]="schedule?.participants.length > 1" [userIdsManaging]="userIdsManaging" [startExpanded]="true" [autoOpenTab]="eInformationSidebarTab.REVIEWS" ></app-information-sidebar>
            </div>
            <ng-template #noCycle>
              <app-information-sidebar [tabsShown]="sidebarTabs" [userIds]="sidebarUserIds" [schedule]="schedule" [cycleId]="schedule.evaluationCycleId" [meetingNavigationControl]="meetingNavigationControl" [publicGoalsOnly]="schedule?.participants.length > 1" [userIdsManaging]="userIdsManaging" [startExpanded]="true" [autoOpenTab]="eInformationSidebarTab.GOALS" ></app-information-sidebar>
            </ng-template>

          </div>
        </div>
      </ng-container>  
    </app-state>
  </div>
</ng-container>