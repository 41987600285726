<tr class="team-table-row">
    <td *ngFor="let indent of [].constructor(depth); index as si" class="text-center" width="50px"></td>

    <!-- Dropdown -->
    <td class="text-center" *ngIf="enabledColumnKeys.length > 0" width="50px" (click)="loadChildren(report)">
        <span class="fal fa-chevron-right dropdown-toggle frankli-view expand-on-hover" [class.dropdown-opened]="report.dropdownOpen" *ngIf="report.hasDirectReports"></span>
    </td>

    <!-- Name -->
    <td [title]="report.user.firstName + ' ' + report.user.lastName" class="p-left-5 p-right-5" width="50px" *ngIf="enabledColumnKeys.includes(eTableColumn.NAME)">
        <span class="owner-img m-bottom-5 m-top-5" [routerLink]="['/profile/',report.user.id]">
            <!-- TODO: Add firstName and lastName -->
          <app-profile-photo [src]="report.user.imageUrl" *ngIf="report.user.imageUrl"></app-profile-photo>
          <img src="../../../../../assets/img/default.svg" *ngIf="!report.user.imageUrl" alt="Profile Picture">
        </span>
    </td>

    <td *ngIf="enabledColumnKeys.includes(eTableColumn.NAME)" (click)="loadChildren(report)">
        <span>{{ report.user.firstName }} {{report.user.lastName }}</span>
        <span class="secondary-pill" *ngIf="report.secondary">
          <app-pill [type]="ePillType.GREEN" [text]="globals.getTerminology(eTerminologyEntity.SECONDARY)"></app-pill>
        </span>
    </td>

    <!-- Goal Count -->
    <td class="text-center" *ngIf="enabledColumnKeys.includes(eTableColumn.GOAL_COUNT)" width="100px">
        <span *ngIf="report.goalsCount > 0" [title]="report.goalsCount + ' goals'">{{report.goalsCount}}</span>
    </td>

    <!-- Active Goal Count -->
    <td class="text-center" *ngIf="enabledColumnKeys.includes(eTableColumn.ACTIVE_GOAL_COUNT)" width="100px">
        <span *ngIf="report.goalsCount > 0" [title]="report.activeCount">{{report.activeCount}}</span>
    </td>

    <!-- Complete Goal Count -->
    <td class="text-center" *ngIf="enabledColumnKeys.includes(eTableColumn.COMPLETE_GOAL_COUNT)" width="120px">
        <ng-container *ngIf="report.goalsCount > 0; else noGoalsInfo">
            <span [title]="report.completeCount">{{report.completeCount}}</span>
        </ng-container>
        <ng-template #noGoalsInfo>
            <div class="no-goals-container">
                <span class="text-nowrap" translate>{{eGoalsMessages.PERSON_HAS_NO_GOALS}}</span>
            </div>
        </ng-template>
    </td>

    <!-- Average Completion -->
    <td class="text-nowrap" *ngIf="enabledColumnKeys.includes(eTableColumn.AVERAGE_COMPLETION)" width="200px">
        <ng-container *ngIf="report.goalsCount > 0">
            <app-progress-bar [startValue]="0" [targetValue]="100" [currentValue]="report.averageCompletion"></app-progress-bar>
        </ng-container>
    </td>

    <!-- Actions -->
    <td class="text-center action-icons" *ngIf="enabledColumnKeys.includes(eTableColumn.ACTIONS)" width="100px">
        <div class="d-inline-block" (click)="openUserGoals(report)"
             [tooltip]="eGoalsMessages.VIEW_USERS_GOALS | translate: { user: report.user.firstName }" [triggers]="globals.tooltipTriggers" placement="left" container="body"
             angulartics2On="click" angularticsAction="TeamGoalsViewUser" angularticsCategory="Support">
            <app-table-action-icon [icon]="'fa-bullseye'"></app-table-action-icon>
        </div>
        <div class="d-inline-block" (click)="openUserGoalCreate(report)"
            [tooltip]="eGoalsMessages.CREATE_A_GOAL | translate: { user: report.user.firstName }"
            [triggers]="globals.tooltipTriggers" placement="left" container="body" angulartics2On="click"
            angularticsAction="TeamGoalsViewUser" angularticsCategory="Support">
            <app-table-action-icon [icon]="'fa-plus'"></app-table-action-icon>
        </div>
    </td>
</tr>

<!-- Dropdown contents -->
<tr *ngIf="report.dropdownOpen && enabledColumnKeys.length > 0">
    <td colspan="50">
        <ng-container *ngIf="!report.childrenLoading">
            <div class="scroll-x">
                <table width="100%" class="goals-subtable">
                    <app-goal-overview-manager-row *ngFor="let directReport of report.directReports"
                                                   (onLoadChildren)="loadChildren($event)"
                                                   (onOpenUserGoals)="openUserGoals($event)"
                                                   (onOpenUserGoalCreate)="openUserGoalCreate($event)"
                                                   [report]="directReport"
                                                   [depth]="depth + 1"
                                                   [enabledColumnKeys]="enabledColumnKeys">
                    </app-goal-overview-manager-row>
                </table>
            </div>
        </ng-container>
        <ng-container *ngIf="report.childrenLoading">
            <div class="text-center">
                <span class="fas fa-fw fa-spin fa-2x fa-spinner m-top-10 m-bottom-10"></span>
            </div>
        </ng-container>
    </td>
</tr>